<section class="w-full h-full flex flex-col justify-between">
  <div class="w-full h-[calc(100%-70px)]">
    <div class="w-full p-5">
      <span class="text-lg md:text-xl font-medium leading-8 text-primary-500"
        >Delete component?</span
      >
      <p class="text-xs sm:text-sm text-primary-500 pt-4">
        Deleting this component from the bucket will also remove conditions &
        actions from the following rules.
      </p>
    </div>
    <div class="px-5 h-[calc(100%-120px)]">
      <span class="text-sm text-primary-300 font-medium">Rules:</span>
      <ul class="h-[calc(100%-30px)] overflow-auto scrollbar">
        @for (rule of questionsPowers; track $index) {
        <li
          class="py-2 border-b border-primary-100 text-primary-500 text-xs sm:text-sm"
        >
          {{rule.ruleName || 'Untitled rule'}}
        </li>
        }
      </ul>
    </div>
  </div>

  <div mat-dialog-actions align="end" class="flex justify-end gap-4 px-4">
    <button
      mat-button
      type="button"
      id="close-dialog-button"
      data-testid="close-dialog-button"
      [mat-dialog-close]="false"
      class="font-semibold"
    >
      CANCEL
    </button>

    <button
      mat-flat-button
      type="button"
      color="warn"
      id="delete-dialog-button"
      data-testid="delete-dialog-button"
      [mat-dialog-close]="true"
      class="font-semibold"
    >
      DELETE
    </button>
  </div>
</section>
