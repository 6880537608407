import { Component, Input, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  ContainerAnswer,
  MemberPermissionData,
  Question,
  QuestionFieldType,
  StationRosterMember,
} from 'src/models';
import { Subject, first, takeUntil } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfinityScrollAutocompleteDirective } from 'src/helpers/directives/infinity-scroll-autocomplete/infinity-scroll-autocomplete.directive';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { ContainerService } from 'src/app/core/container.service';
import { StationService } from 'src/app/core/station.service';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';

/**
 * Reusable component for user relates.
 */
@Component({
  selector: 'app-user-relates',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    InfinityScrollAutocompleteDirective,
    UserAvatarComponent,
    LoadingIndicatorComponent,
  ],
  templateUrl: './user-relates.component.html',
  styleUrls: ['./user-relates.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserRelatesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserRelatesComponent),
      multi: true,
    },
  ],
})
export class UserRelatesComponent implements OnInit, OnDestroy {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Contains all questions associated with the station. */
  @Input({ required: true }) questionSelect: Question[] = [];

  /** The station id. */
  @Input({ required: true }) stationId = '';

  /** Display the form text if editing or not. */
  @Input({ required: true }) isEditForm = false;

  /** The document field to display. */
  @Input() field!: Question;

  /** Members for show list userSelect. */
  members: MemberPermissionData[] = [];

  /** It is loading petition for get members. */
  isLoadingRosterMembers = false;

  /** Init a timeout variable to be used in method get search results . */
  timeout = setTimeout(() => '', 1000);

  /** The form to add this field in the template. */
  userRelatesForm!: FormGroup<{
    /** Form control field selectedValue. */
    selectedValue: FormControl<unknown | null>;
  }>;

  /** Is has ocurred a error in petition. */
  isErrorGetMembers = false;

  /** The field type of the input. */
  fieldTypeEnum = QuestionFieldType;

  /** Member selected in userSelect. */
  memberSelected!: StationRosterMember | undefined;

  /** Question selected in userSelect. */
  questionSelected!: Question | undefined;

  /** Station member roster list. */
  stationRosterMember: StationRosterMember[] = [];

  /** The ContainerAnswer subject. */
  documentAnswer: ContainerAnswer = {
    questionRithmId: '',
    containerRithmId: '',
    questionUpdated: true,
    stationRithmId: this.stationId || '',
    value: '',
    type: this.fieldTypeEnum.AssignedUser || '',
  };

  constructor(
    private fb: FormBuilder,
    private containerService: ContainerService,
    private stationService: StationService,
  ) {
    this.userRelatesForm = this.fb.group({
      selectedValue: [null as unknown, [Validators.required]],
    });
  }

  /**
   * Get the list of questions belongs to current station.
   * @returns Questions for the second operand options.
   */
  get getCurrentStationQuestions(): Question[] {
    return this.questionSelect.filter(
      (que) => que.originalStationRithmId === this.stationId,
    );
  }

  /**
   * Returns whether the question field type is station field type.
   * @param questionType Question type of the field.
   * @returns Boolean.
   */
  isStationField(questionType: QuestionFieldType): boolean {
    return [QuestionFieldType.CreatedBy, QuestionFieldType.UserSelect].includes(
      questionType,
    );
  }

  /**
   * Set up FormBuilder group.
   */
  ngOnInit(): void {
    this.getQuestionType();
    if (this.isEditForm && this.questionSelect) {
      this.getStationUsersRoster();
      if (this.isStationField(this.field.questionType)) {
        const stationQuestion = this.questionSelect.find(
          (station) => station.rithmId === this.field.value,
        );
        this.userRelatesForm.controls.selectedValue.setValue(
          (stationQuestion as Question) || '',
        );
        this.questionSelected = stationQuestion;
      }
    }
  }

  /**
   * The `onTouched` function.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Writes a value to this form.
   * @param val The value to be written.
   */
  // eslint-disable-next-line
  writeValue(val: any): void {
    val &&
      val.length &&
      this.userRelatesForm.setValue(val, { emitEvent: false });
  }

  /**
   * Registers a function with the `onChange` event.
   * @param fn The function to register.
   */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.userRelatesForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(fn);
  }

  /**
   * Registers a function with the `onTouched` event.
   * @param fn The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of this form control.
   * @param isDisabled The disabled state to set.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.userRelatesForm.disable() : this.userRelatesForm.enable();
  }

  /**
   * Get question types list for user relates.
   *
   */
  getQuestionType(): void {
    this.questionSelect = this.questionSelect.filter((item) =>
      [this.fieldTypeEnum.CreatedBy, this.fieldTypeEnum.UserSelect].includes(
        item.questionType,
      ),
    );
  }

  /**
   * Allow the answer to be updated in the documentTemplate through a subject.
   * @param option Option selected.
   */
  updateFieldAnswer(option: Question | MemberPermissionData): void {
    if (option) {
      const selectedOption = Object.prototype.hasOwnProperty.call(
        option,
        'questionType',
      );
      if (selectedOption) {
        this.questionSelected = option as Question;
        this.memberSelected = undefined;
      } else {
        this.memberSelected = option as StationRosterMember;
        this.questionSelected = undefined;
      }
      this.documentAnswer.value = option.rithmId;
      this.documentAnswer.type =
        (this.questionSelected && this.questionSelected.questionType) ||
        this.fieldTypeEnum.User;
      this.documentAnswer.label = selectedOption
        ? this.questionSelected && this.questionSelected.prompt
        : this.memberSelected?.firstName + ' ' + this.memberSelected?.lastName;
    } else {
      this.documentAnswer.value = '';
      this.memberSelected = undefined;
      this.questionSelected = undefined;
    }
    this.containerService.updateAnswerSubject(this.documentAnswer);
  }

  /**
   * Get Users Roster for a given Station.
   */
  getStationUsersRoster(): void {
    this.stationRosterMember = [];
    this.isLoadingRosterMembers = true;
    this.stationService
      .getStationWorkerRoster(this.stationId)
      .pipe(first())
      .subscribe({
        next: (members) => {
          this.stationRosterMember = members;
          this.isLoadingRosterMembers = false;
          if (
            this.isEditForm &&
            !this.isStationField(this.field.questionType)
          ) {
            const selectedRoster = this.stationRosterMember.find(
              (member) => member.rithmId === this.field.answer?.value,
            );
            this.userRelatesForm.controls.selectedValue.setValue(
              (selectedRoster && (selectedRoster as StationRosterMember)) || '',
            );
            this.memberSelected = selectedRoster;
          }
        },
        error: () => {
          this.isErrorGetMembers = true;
          this.isLoadingRosterMembers = false;
        },
      });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
