import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Power } from 'src/models';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TermsGeneric } from 'src/helpers';

interface MatData {
  /** Power to show. */
  powers: Power[];
}

/**
 * Confirm Remove question modal.
 */
@Component({
  selector: 'app-confirm-remove-question-frame-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './confirm-remove-question-frame-modal.html',
  styleUrls: ['./confirm-remove-question-frame-modal.scss'],
})
export class ConfirmRemoveQuestionFrameModalComponent {
  /** Questions powers to remove. */
  questionsPowers: Power[] = [];

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  constructor(@Inject(MAT_DIALOG_DATA) private data: MatData) {
    this.questionsPowers = data.powers;
  }
}
